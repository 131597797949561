/* список меню перетаскивание */
.content__ul{
    padding: 0;
}
.row__li{
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    background-color: $white;
    padding: 2px 10px;

    border-bottom: 1px solid $lightGray;

    & .side__row__li{
        width: 20%;
        &:last-child{
            text-align: right;
        }
    }
}
.row__li__val{
    &:hover{
        background-color: $lightYellow;

    }
}


.ajax__btn__click{
    display: none;
}
.ajax__input__hide{
    display: none;
}
.placeholder__ul{
    background-color: $Yellow;
    height: 40px;
}
#sorting{
    display: none;
}
.prompt__text{
    padding-left: 10px;
    color: $gray;
}
    /* --список меню перетаскивание */