/* переменные цвет */
/* $backLight: #F3F6F9; */
$backLight: #FFFEF9;
$white: #fff;
$brand: #EE7C3B;
$dark: #14121F;
$lightGray: #D1D5DA;
$lightBrand: #FDF8F4;

$gray: #73726c;
$lightYellow: #FFFAE6;
$midleYellow: #FFF5CF;
$Yellow: #FFE583;