body {
    font-family: 'Roboto', sans-serif;
    background-color: $backLight;
    font-size: 14px;

}

.content__wrap {
    margin-left: 300px;
    width: 80%;
    padding: 15px 20px;
    transition: all 0.3s ease 0s;
}

.content__wrap__hide {
    margin-left: 68px;
    width: 95%;
}


.icon__prof {
    margin-right: 5px;
}

.logo__left {

    font-size: 28px;
    font-weight: 700;
    color: $brand;

}

.btn-brand {
    /* background-color: $brand; */
    border: 2px solid $dark;
    border-radius: 13px;
}

.btn-brand:hover {
    border-color: lighten($brand, 10%);
    background-color: lighten($brand, 10%);

}


.logo {
    margin-left: 18px;
    width: 100%;
    display: flex;
    align-items: center;

    & a {
        display: flex;
        align-items: center;
        text-decoration: none;

        color: $brand;

    }
}

/* logo */

.logo__svg {
    margin-right: 20px;
}

.logo__text {
    font-size: 24px;
    font-weight: 700;
}

/* оформление контента contetent__box */
.contetent__box {
    margin-top: 15px;
    width: 100%;
    padding: 20px 0;
    background-color: $white;
    border-radius: 15px;
    box-shadow: 0px 6px 20px rgba(20, 18, 31, 0.2);
}

.contetent__box__accordion {
    margin-top: 15px;
    width: 100%;

    background-color: $white;
    border-radius: 15px;
    box-shadow: 0px 6px 20px rgba(20, 18, 31, 0.2);
}

.contetent__box__header {
    font-size: 20px;
    font-weight: 700;
    padding: 15px;
}

.card {
    border-left: 0;
    border-right: 0;
}

.btn__accordion {
    font-size: 16px;
    color: $dark;
    text-decoration: none;

    &:hover {
        color: $gray;
    }
}

.content__side {

    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* border-bottom: 1px solid $lightGray; */
    padding: 5px 20px 5px 20px;
    font-size: 14px;
    transition: all 0.3s ease 0s;

    &:hover {
        /*  background-color: lighten($brand, 35%); */
        box-shadow: 0px 2px 12px rgba(20, 18, 31, 0.2);
        transition: all 0.3s ease 0s;

    }

    &:before {
        position: absolute;
        bottom: 0;
        left: 1.5%;
        width: 97%;
        border-bottom: 1px solid $lightGray;
        content: "";
    }
}

/* content__side */
.left__side {
    width: 49%;
    border-right: 1px solid $lightGray;
}

.right__side {
    width: 52%;
    padding-left: 7px;
}

.side__small {


    font-size: 20px;
    cursor: pointer;

    & a {
        color: $dark;

        &:hover {
            color: $brand;
        }
    }
}

.side {
    border-right: 1px solid $lightGray;
    padding-left: 20px;


}

.side_btn {
    padding: 20px 10px;
    display: flex;
    justify-content: flex-end;


}

.btn__in__box {
    color: $dark;
    background-color: $Yellow;
    border-radius: 7px;

    &:hover {
        background-color: darken($Yellow, 15%);
        color: $dark;
    }
}

/* адаптивная таблица */

table.content__table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
    color: $dark;
}

.tr__content {
    &:hover {
        box-shadow: 0px 2px 12px rgba(20, 18, 31, 0.2);
        transition: all 0.3s ease 0s;
    }
}


table.content__table th,
table.content__table td {
    text-align: left;
    padding: 5px 3px 5px 15px;

}

.content__table td {
    border-bottom: 1px solid $backLight;
    border-top: 1px solid $backLight;
}

.td__link {
    color: $dark;
    background-color: $lightGray;
    border-radius: 7px;

    padding: 4px 11px;

    &:hover {
        background-color: $Yellow;
        color: $dark;
    }
}

.td__icon {
    text-align: right !important;
    padding: 3px 15px 3px 3px !important;
    border: 0;


}

.content__table__small {
    font-size: 12px;
    /* width: 1500px !important;
    overflow: auto !important; */
    & th {

        padding: 3px 2px 3px 15px !important;
    }

    & td {
        padding: 3px 2px 3px 15px !important;
    }
}

/* content__table__small */
.contetent__box {
    overflow: auto !important;
}

@media screen and (max-width: 600px) {
    table.content__table {

        border: 0;
    }


    table.content__table thead {
        border: none;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
    }

    table.content__table tr {

        background-color: $backLight;

        border-bottom: 1px solid #ddd;
        display: block;
        margin-bottom: 30px;
    }

    table.content__table td {
        display: block;
        text-align: right;
    }

    table.content__table td::before {
        content: attr(data-label);
        float: left;
        font-weight: bold;

    }

    table.content__table td:last-child {
        border-bottom: 0;
    }
    .td__link {
        width: 100%;
        padding: 6px 12px;
    }
}

/* @media screen and (max-width: 600px) */


/* ---адаптивная таблица */

/* ---оформление контента contetent__box */
/* оформление формы form__box */
.form__box {
    width: 100%;
    margin-top: 20px;
    padding: 20px;
    background-color: $white;
    border-radius: 15px;
    box-shadow: 0px 6px 20px rgba(20, 18, 31, 0.2);
}

/* ---оформление формы form__box */
/* страничка входа login */
.page__form__wrap {
    width: 100%;
    height: 100vh;
    background-color: $brand;
    display: flex;
    align-items: center;
    justify-content: center;
}

.card {
    background-color: rgba(255, 255, 255, 0.6);

    -webkit-backdrop-filter: blur(3px);
    backdrop-filter: blur(3px);

    & input {
        background-color: rgba(255, 255, 255, 0.1) !important;
        border: 2px solid $white;

    }
}

.card-header {
    background-color: transparent !important;
    border: none;
}

.card {
    border-radius: 15px;
}

.form-control:focus {
    border-color: $brand;
    box-shadow: none;
}

.btn__form {
    background-color: $brand;
    color: $white;
    border-radius: 10px;

    &:hover {
        background-color: #c24d0a;
        color: $white;
    }
}

.btn__form__link {
    color: $brand;

    &:hover {
        color: #c24d0a;
    }
}

.btn__form__nav {
    background-color: $white;
    border-radius: 100%;
    padding: 0px !important;
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
        background-color: $lightGray;
    }

    & i {
        color: $brand;
    }
}

.form-check-input:checked {
    background-color: $brand;
}

/* ---страничка входа login */
/* подсказки */
.tooltip-inner {
    min-width: 150px;
    max-width: 300px;

}

.tooltip-btn {
    font-size: 18px;
    color: #949494;

    padding: 0;
    line-height: 1px;
    border: 0;
    max-height: 19px;
    margin-left: 3px;
}

/* --подсказки */
.form__container {
    max-width: 50%;
    margin-left: auto;
    margin-right: auto;
}

/* табы */
.contetent__box__tab {
    margin-top: 15px;
    width: 100%;
    height: 80vh;
    background-color: $white;
    border-radius: 15px;
    box-shadow: 0px 6px 20px rgba(20, 18, 31, 0.2);
}

.tab__box {
    padding-left: 20px;
    display: flex;
    height: 100%;

}

.title__tab {
    padding: 20px 0;
    height: 100%;
    width: 20%;

    & .nav-link {
        color: $dark;

    }

    & .nav-link.active {
        background-color: $brand;
        border-radius: 20px 0 0 20px;
    }

}

.content__tab {
    height: 100%;
    width: 100%;
    border-radius: 15px;
    padding: 20px;
    box-shadow: 0px 6px 20px rgba(20, 18, 31, 0.2);

}

.tab__info__row {

    display: flex;
    justify-content: space-between;
    padding: 5px 0;

    &:hover {
        box-shadow: 0px 2px 12px rgba(20, 18, 31, 0.2);
        transition: all 0.3s ease 0s;
    }
}

.tab__info__title {
    width: 100%;
    text-align: right;
    padding-right: 10px;
    border-right: 2px solid $lightGray;
    font-weight: 600;
}

.tab__info__content {
    width: 100%;
    padding-left: 10px;
}

/* --табы */
.ul__ui {
    width: 100%;
}

.li__ui {
    width: 100%;
    height: 30px;
    background-color: #EE7C3B;
    border-color: #180e09;
    margin-top: 3px;

}


