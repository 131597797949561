/* левое меню */
.nav__wrapper {
    display: flex;
}


.left-nav {
    position: fixed !important;
   /*  top: 0; */
    width: 300px;
    margin-left: 0;
    background-color: $Yellow;
    height: 100vh;
    transition: all 0.4s;
    white-space: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    box-shadow: 6px 0px 20px rgba(20, 18, 31, 0.2);
}

.left-nav a {

    display: inline-block;
}


.left-nav__hide {
    width: 60px;
    transition: all 0.4s;
    overflow: hidden;
    z-index: 11;


}

.left-nav__hide:hover {
    width: 300px;

    transition: all 0.3s ease 0s;


}

/* навигация */
.main__menu {
    margin-top: 20px;

    & ul {
        list-style: none;
        padding: 0;
    }

    & li {
        list-style: none;
        padding: 0;
        position: relative;
    }

    & a:hover {
        text-decoration: none;
    }
}/* main__menu */


.header__burger__wrap{
    position: fixed;
}
.togle__link {
    padding: 6px 16px 6px 70px;
    font-size: 16px !important;
    color: lighten($dark, 30%);

    &:hover {
        color: $white;
    }
}
@media screen and (max-width: 800px){

    .content__wrap{
        margin-left: 15px;
    }
    .left-nav__hide{
        width: 0px;
    }
.header__burger__wrap.active__wrap{

    transition: all 0.3s ease 0s;
}
}/* @media screen and (max-width: 600px) */

/* --- навигация */
/* --- левое меню */
/* 1 */
.navbar__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

/* верхнее меню */
/* ИКОНКА МЕНЮ */
.header__burger__wrap{
    margin: 0;
    padding: 5px 5px 5px 14px;
    transition: all 0.3s ease 0s;
    width: 300px;
    background-color: $midleYellow;
    height: 60px;
   display: flex;
   align-items: center;
   box-shadow: 6px 0px 20px rgba(20, 18, 31, 0.2);
}
.header__burger {




    display: block;
    position: relative;
    width: 33px;
    height: 28px;
}
.header__burger__text{
    font-size: 20px;

    padding: 6px 15px 6px 22px;
    color: $dark;
}
.header__burger:before,
.header__burger:after {
    content: "";
    background-color: $dark;
    position: absolute;
    width: 100%;
    height: 4px;
    right: 0;
    transition: all 0.3s ease 0s;
}

.header__burger span {
    content: "";
    background-color: $dark;
    position: absolute;
    width: 80%;
    height: 4px;
    right: 0;
    top: 12px;
    transition: all 0.3s ease 0s;
}

.header__burger::before {
    top: 0px;
}

.header__burger::after {
    bottom: 0;
}

.header__burger.active:before {
    transform: rotate(45deg);
    top: 12px;
    transition: all 0.3s ease 0s;
}

.header__burger.active:after {
    transform: rotate(-45deg);
    bottom: 12px;
    transition: all 0.3s ease 0s;
}

.header__burger.active span {
    transform: scale(0);
    transition: all 0.3s ease 0s;
}
/* -1 */
.top__nav__wrap{
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 60px;
    width: 100%;
    background-color: $lightYellow;
    padding: 0;
}

.top__left__wrap{
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

}
.logo__admin{
    display: flex;
    align-items: center;
    padding-left: 90px;
    font-size: 16px;
    vertical-align: middle;

}
.logo__admin__text{
    padding-left: 90px;
    font-size: 16px;
    vertical-align: middle;
  /*   height: 16px; */
}
.menu__link{

    color: $dark;
    & a{
        width: 100%;
        font-size: 18px;
        padding: 6px 15px 6px 20px;
    }
    & i{
        font-size: 24px;
        margin-right: 16px;
    }
    &:hover{
        background-color: $dark;
        & a{
            color: $white;
        }
        &:active{
            background-color: $dark;
            & a{
                color: $white;
            }
        }

    }
}

.menu__header{
    font-size: 17px;
    /* padding: 6px 15px 6px 52px; */
    padding: 5px;
    background-color: $midleYellow;
    text-align: center;
}
.header__burger__wrap.header__burger__wrap-min{
    width: 60px;
    transition: all 0.3s ease 0s;
    & .header__burger__text{
        display: none;
        transition: all 0.3s ease 0s;
    }
}
.header__burger__wrap-min2{
    width: 300px;
    transition: all 0.3s ease 0s;
    & .header__burger__text{
        display: block;
        transition: all 0.3s ease 0s;
    }
}
.main__menu__active{
    background-color: $dark;
    color: $white;
}
.btn__top__nav{
    background-color: $Yellow;
    margin-right: 15px;
    &:hover{
        background-color: lighten($Yellow,10%);
    }
}