/* // Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';*/

// Bootstrap
@import '~bootstrap/scss/bootstrap';
/* @import 'jquery-ui-min'; */
@import 'color';
@import 'main';
@import 'left-menu';
@import 'top-nav';
@import 'front__menu';
@import 'admin__content';
/* @import 'front_pages'; */



