/* модальное окно подтверждение */
.modal__confirm {
    background-color: $white;
}

.modal__confirm__body {
    font-size: 16px;
    border: 0;
}

.modal-header {
    border: 0;
}

.modal-footer {
    border: 0;
}

.text_focus {

    font-weight: 800;
}

/* ---модальное окно подтверждение */
.prompt_box {
    padding: 10px 15px;
    margin: 10px;
    border-radius: 10px;
    background-color: #c3d8ff;
    width: max-content;
}

.side__row__li__box {
    display: flex;
    max-width: 300px;
}

.box__label {
    position: relative;
    padding: 0 8px;
    background-color: $gray;
    border-radius: 4px;
    color: white;
    margin-right: 4px;

    & .close__togle {
        position: absolute;
        cursor: pointer;
        z-index: 44;
        right: -8px;
        top: -8px;
        width: 20px;
        height: 20px;
        background-color: black;
        color: white;
        border-radius: 100%;
        display: none;
        align-items: center;
        justify-content: center;
    }

    &:hover {
        & .close__togle {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}

.box__label__all {
    background-color: $lightGray;
}
